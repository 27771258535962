import "./header.scss"

import React, { useEffect, useState } from "react"

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = function (e) {
        var scrollY = window.pageYOffset || document.documentElement.scrollTop
        var header = document.querySelector("header")

        if (scrollY >= 1) {
          header.classList.add("shrink")
        }

        scrollY === 0 && header.classList.remove("shrink")
        this.lastScroll = scrollY
      }
    }
  })

  if (typeof window !== "undefined") {
    if (isMenuOpen === true) {
      document.body.classList.add("appleFix")
    } else {
      document.body.classList.remove("appleFix")
    }
  }

  return (
    <header>
      <button
        className="header__mobile"
        onClick={e => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? "Close" : "Menu"}
      </button>
    </header>
  )
}

export default Header
