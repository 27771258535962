import React from "react"
import { CookiesProvider, withCookies } from "react-cookie"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"

import "../assets/styles/app.scss"

class Layout extends React.Component {
  state = {
    cookieOpen: false,
  }

  componentDidMount() {
    const { cookies } = this.props
    const isAcceptedCoookie = !!cookies.get("cookie-accept-project-name")
    !isAcceptedCoookie && this.setState({ cookieOpen: true })
  }

  acceptCookie = () => {
    const { cookies } = this.props

    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept-unity", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false })
    })
  }

  render() {
    const { children } = this.props

    return (
      <CookiesProvider>
        <Header />
        {children}
        <Footer />
      </CookiesProvider>
    )
  }
}

export default withCookies(Layout)
